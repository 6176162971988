/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body{
	font-family: 'Roboto', sans-serif;
}
img {
    max-width: 100%;
}

button:hover, button:focus, a:hover, a:focus {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #B3B3B3;
  }
  .form-control::-moz-placeholder { /* Firefox 19+ */
    color: #B3B3B3;
  }
  .form-control:-ms-input-placeholder { /* IE 10+ */
    color: #B3B3B3;
  }
  .form-control:-moz-placeholder { /* Firefox 18- */
    color: #B3B3B3;
  }
  .dataTables_wrapper .dataTables_filter{
    margin-bottom: 30px!important;
}
div.dataTables_wrapper div.dataTables_length select {
    border-radius: 25px!important;
    border:1px solid #ACD4F7;
    color: #000;
    padding: 5px 10px
}
div.dataTables_wrapper div.dataTables_length label {
    color: #000;
}
div.dataTables_wrapper div.dataTables_filter input {
    border-radius: 25px!important;
    border:1px solid #ACD4F7;
    padding:5px 15px;
}
div.dataTables_wrapper div.dataTables_filter input:hover,
div.dataTables_wrapper div.dataTables_filter input:focus {
    outline: none;
    box-shadow: none;
}
div.dataTables_wrapper div.dataTables_filter label {
    color: #000;
}
div.dataTables_wrapper div.dataTables_info {
    color: #000;
    float: left;
}
table thead tr:first-child{
    background: transparent;
    border-radius: 10px 10px 0px 0px!important;
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    color: #000;
}
table tbody{
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000;
    vertical-align: middle;
    
}  

table.dataTable>tbody>tr.odd {
    background: #fff;
}
 
table tbody tr td{
    word-break: break-all;
}
table thead tr th{     
    font-weight: 400!important;
}
 
table.table-bordered.dataTable th, table.table-bordered.dataTable td {
    vertical-align: middle;
    text-align: left;
    border: 1px solid #DADADA;
    line-height: 30px;
}
 
table.table-bordered.dataTable th{
    font-size: 18px;
}
table.table-bordered.dataTable td{
    font-size: 12px;
    text-transform: capitalize;  
}
.dataTables_info{
   color:#000;
}
table.dataTable.no-footer {
   border-bottom: 0px!important;
}

.dataTables_length { float: left !important; margin-top: 5px; }
.dataTables_length select {
    margin: 0px 5px;
}

.paging_simple_numbers {
    float: right;
    margin-top: 10px !important;
}
.products_table {
    margin-bottom: 20px !important;
}
.paging_simple_numbers { display: flex; }
.paginate_button.previous {
    background-image: url(../src/assets/images/prev_arw.png);
	background-repeat: no-repeat;
    background-position: center;
	display: flex;
    font-size: 0px;
    width: 25px;
    height: 24px;
    background-size: 22px;
    cursor: pointer;
}
.paginate_button.next {
    background-image: url(../src/assets/images/next_arw.png);
	background-repeat: no-repeat;
    background-position: center;
	display: flex;
    font-size: 0px;
    width: 25px;
    height: 24px;
    background-size: 22px;
    cursor: pointer;
}
.paginate_button.previous:hover { 
    background-color: transparent !important;
    background-image: url(../src/assets/images/prev_arw.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 22px !important;
}
.paginate_button.next:hover { 
    background-color: transparent !important;
    background-image: url(../src/assets/images/next_arw.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 22px !important;
}
.paging_simple_numbers span {
    display: flex;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    display: flex !important;
    padding: 0px !important;
    border: none !important;
    min-width: inherit;
}
.paging_simple_numbers span a {
    width: 25px;
    height: 25px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #E7E7E7;
    color: #050505;
    font-size: 15px;
    margin-right: 3px;
    cursor: pointer;
}
.mat-datepicker-toggle .mat-button-wrapper svg { display: none !important; }
.mat-datepicker-toggle .mat-button-wrapper {
    background-image: url(../src/assets/images/datepicker.png) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    display: flex;
    width: 28px;
    height: 28px;
    background-size: 24px;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0px 10px 0px 20px !important;
    display: flex;
    align-items: center;
}
.swalbg .swal2-actions { margin-top: 10px !important;}
.swalbg button.swal2-confirm.swal2-styled {
    background-color: #3CA1DC;
    border-radius: 20px;
    padding: 8px 25px;
    font-size: 16px;
}
.swalbg .swal2-icon { transform: scale(0.7); margin-top: 10px; }
.swalbg div#swal2-html-container { margin-top: 0px; }
.swalbg .swal2-popup { width: 25em; }
.swalbg .swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(58 156 213 / 57%);
}
@media only screen and (max-width: 767px) {
    .welcome_logo { 
      max-width: 195px;
      margin-left: 10px !important;
      padding-left: 0px;
      margin-top: 10px !important;
    }
    .dataTables_length { margin-top: 0px !important; }
    .dataTables_length { margin-bottom: 10px !important; }
    .dataTables_wrapper .dataTables_filter { width: 100%; margin-top: 10px; margin-bottom: 10px!important; float: right; }
    .dataTables_wrapper div.dataTables_length label { font-size: 12px; }
    div.dataTables_wrapper div.dataTables_length select { margin: 0px 2px; padding: 5px 3px; width: 46px; }
    div.dataTables_wrapper div.dataTables_filter label { font-size: 12px; width: 100%; }
    div.dataTables_wrapper div.dataTables_filter input { padding: 5px 8px; width: 83%; }
    table.table-bordered.dataTable th, table.table-bordered.dataTable td { line-height: 20px; }
    div.dataTables_wrapper div.dataTables_info { font-size: 13px;}
    .paging_simple_numbers span a { font-size: 13px; }
    .paging_simple_numbers { justify-content: flex-end; }
    .swalbg div#swal2-html-container { font-size: 14px !important; }
    .swal2-html-container { font-size: 14px !important; }
    .swal2-title { padding: 0px !important;}
    .swal2-actions { margin-top: 6px !important;}
    .swal2-icon { transform: scale(0.7); margin: auto !important; }
    table.dataTable>thead .sorting:before, table.dataTable>thead .sorting_asc:before { bottom: 0.8em !important; }
}